<template>
  <div id="productViewByType">
   
   <select  @change="listProducts" v-model="prd" >
     <option value="Single Lever Mixer">Single Lever Mixer</option>
     <option value="Shattaf Set">Shattaf Set</option>
     <option value="Double Lever Mixer">Double Lever Mixer</option>
     <option value="Shower Set">Shower Set</option>
     <option value="Single Function Water Tap">Single Function Water Tap</option>
     <option value="Valve">Valve</option>
     <option value="Hose">Hose</option>
     <option value="Drainer">Drainer</option>
     <option value="Water Filter">Water Filter</option>
     <option value="Water Pump">Water Pump</option>
     <option value="H- PVC Pipe Fitting">H- PVC Pipe Fitting</option>
     <option value="PP-R Fitting">PP-R Fitting</option>
     <option value="C-PVC Pipe Fitting">C-PVC Pipe Fitting</option>
     <option value="Drainage Fitting">Drainage Fitting</option>
   </select>
    <div class="cardContainer">
      <div v-for="(product,index) in this.products" :key="index">
        <h4 v-if="product.series !== null">
          {{product.series}}
          <span class="series">series</span>
        </h4>
        <div class="sectionContainer">
          <div class v-for="(items,index) in product.items" :key="index">
            <div class="card" v-for="(item, index) in items" :key="index">
              <img :src="require('@/products/'+item.img)" />
              <div class="textContainer">
                <p>{{item.name}}</p>
                <small>{{item.id}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prd: 'Single Lever Mixer',
      products: []
    };
  },
  created() {
    //this.prd = ;
    var link = this.prd + ".json";
      this.products = require("../products/data/" + link);
  },
  methods: {
    listProducts() {
      var link = this.prd + ".json";
      this.products = require("../products/data/" + link);
    }
  }
};
</script>

<style lang="scss" scoped>
#productViewByType {
  width: 100%;
  padding: 1rem;
  margin-top: $headerHeight;
}
h3{
  color:$blue;
}
h4{
  color:$darkBlue;
}
.series {
  margin-left: 2px;
  color: grey;
  font-weight: 300;
}
select{
  padding:1rem;
  border:1px solid $blue;
  background-color: white;
  text-indent: 5px;
  font-size: 1.05rem;
  margin:1rem 0;
}
.sectionContainer{
  display:inline-flex;
  flex-wrap:wrap;
  justify-content: center;
  @include media-md{
    justify-content: flex-start;
  }
}
.cardContainer {
  width: inherit;
  margin-top: 1rem;
  background-color: $white;
  overflow: hidden;
  .card {
    min-width: 200px;
    max-width: 250px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 1px 5px 1px #dedede;
    display: inline-block;
    transition:transform 0.5s;
    img {
      width: 100%;
      height: auto;
    }
    .textContainer {
      p {
        color: $blue;
        margin: 0;
      }
    }
    &:hover{
        transform:translateY(2px);
      }
  }
}
</style>
